import {
  WppActionButton,
  WppButton,
  WppDivider,
  WppIconArrow,
  WppStep,
  WppStepper,
  WppTypography,
} from '@platform-ui-kit/components-library-react'
import { HierarchyNode } from '@wpp-open/core/types/mapping/common'
import { useOs } from '@wpp-open/react'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { CreateProjectAttribute, CreateProjectDTO } from 'api/projects/fetchers/createProjectApi'
import { useCreateProjectApi } from 'api/projects/mutation/useCreateProjectApi'
import { useFetchWorkflowTemplateByIdApi } from 'api/templates/queries/useFetchWorkflowTemplateByIdApi'
import { Flex } from 'components/common/flex/Flex'
import { SideModalContentSkeleton } from 'components/common/sideModalSkeleton/SideModalContentSkeleton'
import { tableActions } from 'components/common/table'
import { SideModal } from 'components/surface/sideModal/SideModal'
import { ACTION_ANALYTICS, PAGE_ANALYTICS } from 'constants/analytics'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { TableKey } from 'constants/table'
import { useForm } from 'hooks/form/useForm'
import { useTrackAction, useTrackPage } from 'hooks/useAnalytics'
import { useHierarchy } from 'hooks/useHierarchy'
import { useTimeTracker } from 'hooks/useTimeTracker'
import { useToast } from 'hooks/useToast'
import { useWrike } from 'hooks/useWrike'
import { WrikeConnect } from 'pages/components/projectModal/components/integrations/components/WrikeConnect'
import { WrikeIntegrationsMessage } from 'pages/components/projectModal/components/integrations/components/WrikeIntegrationsMessage'
import { ProjectForm } from 'pages/components/projectModal/components/projectForm/ProjectForm'
import { ProjectTemplateForm } from 'pages/components/projectModal/components/projectTemplateForm/ProjectTemplateForm'
import styles from 'pages/components/projectModal/CreateProjectModal.module.scss'
import {
  fixDefaultValueForForm,
  getAttributesDefaults,
  normalizeProjectFormDate,
  useCreateProjectTemplatesCount,
  useHierarchyDefaults,
} from 'pages/components/projectModal/utils'
import { withAttributes } from 'pages/components/projectModal/withAttributes'
import { TemplateCard } from 'pages/components/templateCard/TemplateCard'
import { modalValidationScheme } from 'pages/dashboard/components/utils'
import { queryClient } from 'providers/osQueryClient/utils'
import { ProjectAttribute, ProjectAttributeClass } from 'types/projects/attributes'
import { ContextHierarchy, ProcessType, ProjectStatus, ProjectType } from 'types/projects/projects'
import { createNiceModal, NiceModalWrappedProps } from 'utils/createNiceModal'
import { CustomError, is409Error, is422Error } from 'utils/error'
import { routesManager } from 'utils/routesManager'

interface Props extends NiceModalWrappedProps {
  templatePresetId?: string
  attributes?: ProjectAttribute[]
}

enum CreateStep {
  DETAILS = 'details',
  TEMPLATE_FORM = 'templates',
  INTEGRATIONS = 'integrations',
}

const defaultValues = {
  name: '',
  type: ProjectType.CAMPAIGN,
  description: '',
  processType: ProcessType.LINEAR,
  dueDate: [],
  templateId: '',
  wrikeProjectId: '',
  wrikeConnectionToggle: false,
}

const CreateProjectModal = ({ isOpen, onClose, onCloseComplete, templatePresetId, attributes }: Props) => {
  const { t } = useTranslation()
  const { showToast } = useToast()
  const {
    osContext: { navigationTree, tenant, userDetails },
  } = useOs()

  const { calculateTimeDifferenceInSeconds } = useTimeTracker()

  const navigate = useNavigate()
  const [currentStepIdx, setCurrentStepIdx] = useState(0)
  const { mutateAsync: createProject } = useCreateProjectApi()
  const [wrikeError, setWrikeError] = useState(null)

  useTrackPage(PAGE_ANALYTICS.PAGE_PROJECT_CREATE)
  const { trackAction } = useTrackAction()

  const { hierarchyOrder } = useHierarchy()
  const { integrationAvailable, isIntegrationsLoading } = useWrike()

  const systemAttributes = useMemo(
    () =>
      attributes
        ?.filter(attribute => attribute.classification === ProjectAttributeClass.SYSTEM)
        .map(fixDefaultValueForForm) || [],
    [attributes],
  )

  const customAttributes = useMemo(
    () =>
      attributes
        ?.filter(attribute => attribute.classification === ProjectAttributeClass.CUSTOM && attribute.enabled)
        .map(fixDefaultValueForForm) || [],
    [attributes],
  )

  const { systemDefaults, customDefaults } = useMemo(
    () => ({
      systemDefaults: getAttributesDefaults(systemAttributes || []),
      customDefaults: getAttributesDefaults(customAttributes || []),
    }),
    [customAttributes, systemAttributes],
  )

  const hierarchyDefaults = useHierarchyDefaults()

  const form = useForm({
    defaultValues: {
      ...defaultValues,
      // system attributes will be on the root level, custom - as a separate group
      ...systemDefaults,
      customAttributes: customDefaults,
      hierarchy: hierarchyDefaults,
    },
    validationSchema: modalValidationScheme(hierarchyOrder, [...systemAttributes, ...customAttributes]),
  })

  const {
    handleSubmit,
    formState: { isSubmitting, errors },
    getValues,
    setValue,
    trigger,
    watch,
  } = form

  const processType = watch('processType')
  const { isTemplatesCountLoading } = useCreateProjectTemplatesCount()

  const { data: template, isLoading: isTemplateLoading } = useFetchWorkflowTemplateByIdApi({
    params: { id: getValues().templateId! },
    enabled: !!getValues().templateId,
  })

  useEffect(() => {
    if (!templatePresetId) return
    setValue('templateId', templatePresetId)
  }, [templatePresetId, setValue])

  useEffect(() => {
    if (isTemplateLoading || !template) return
    setValue('processType', template.processType)
  }, [template, isTemplateLoading, setValue])

  const onSubmit = handleSubmit(
    async () => {
      await handleSave()
    },
    errors => console.error('Form is not valid', errors),
  )

  const handleSave = useCallback(async () => {
    setWrikeError(null)
    const { templateId, wrikeProjectId, wrikeConnectionToggle, hierarchy, customAttributes, ...systemAttributes } =
      getValues()

    const selectedWorkspace = hierarchyOrder.map(order => hierarchy[order as keyof typeof hierarchy] || '') as string[]
    const contextWorkspace = [...selectedWorkspace].reverse().find(el => el !== '') || ''

    const projectAttributes = (attributes || [])
      .map(attr => {
        if (attr.contractName === 'startDate_endDate') {
          const value = !!systemAttributes.dueDate.length
            ? Object.values(normalizeProjectFormDate(systemAttributes.dueDate)).join('_')
            : null
          return {
            id: attr.id,
            value,
          }
        }

        // CONTEXT_WORKSPACE : "context" can be changed to any other key, so this comment is needed to track the key across project
        if (attr.contractName === 'contextWorkspace') {
          return {
            id: attr.id,
            value: contextWorkspace,
          }
        }

        const formAttributes = { ...systemAttributes, ...customAttributes }
        if (attr.contractName in formAttributes) {
          return {
            id: attr.id,
            value: formAttributes[attr.contractName as keyof typeof formAttributes] || '',
          }
        }

        return null
      })
      .filter(Boolean)

    const contextHierarchy: ContextHierarchy[] = selectedWorkspace.map((id, index) => {
      const node = navigationTree.mapping[id] as HierarchyNode
      return {
        title: hierarchyOrder[index],
        name: node?.name || null,
        value: node?.azId || null,
        alias: node?.alias || null,
        // TODO any should be gone when `@wpp-open/core` will be updated to v6+
        originalName: (node as any)?.originalName || node?.name || null,
      } as ContextHierarchy
    })

    try {
      let projectBody: CreateProjectDTO = {
        projectAttributes: projectAttributes as CreateProjectAttribute[],
        contextHierarchy,
        status: ProjectStatus.TO_DO,
        ...(!!templateId && { templateId }),
      }

      if (wrikeConnectionToggle) {
        projectBody = {
          ...projectBody,
          wrikeProjectId,
        }
      }

      const {
        data: { id },
      } = await createProject(projectBody)

      Promise.all([
        await queryClient.invalidateQueries([ApiQueryKeys.PROJECTS_FETCHER]),
        await queryClient.invalidateQueries([ApiQueryKeys.PROJECTS_INFINITE]),
        await tableActions.reload([TableKey.PROJECT_LIST]),
      ])

      const analyticsData = {
        name: systemAttributes.name.trim(),
        workspace_name: tenant.name,
        workspace_type: tenant.tenantType,
        user_agency: userDetails?.agency,
        project_hierarchy: contextHierarchy,
        project_type: systemAttributes.type,
        process_type: processType,
        is_created_from_template: !!templateId,
        connected_to_wrike: !!wrikeProjectId,
        duration_in_seconds: calculateTimeDifferenceInSeconds(),
      }

      trackAction(ACTION_ANALYTICS.ACTION_PROJECT_CREATE, analyticsData)

      navigate(routesManager.project.root.getURL({ id }))
      onCloseComplete()
    } catch (e: any) {
      const is422 = is422Error(e)
      const is409 = is409Error(e)

      if (e?.response?.data?.detail?.code?.includes('WRIKE_')) {
        setWrikeError(e)
        return
      }

      const CODE = (e as any).response?.data?.detail?.code

      let message = t('modals.create_project.toast_error_common')

      if (is422 && CODE === CustomError.TEMPLATE_NOT_FOUND) {
        message = t('modals.create_project.template_not_found')
        setValue('templateId', '')
        queryClient.invalidateQueries([ApiQueryKeys.WORKFLOW_TEMPLATES_INFINITE])
      }

      if (is409) message = t('modals.create_project.toast_error_duplicate')

      showToast({
        type: 'error',
        message,
      })
    }
  }, [
    getValues,
    hierarchyOrder,
    attributes,
    navigationTree.mapping,
    createProject,
    tenant.name,
    tenant.tenantType,
    userDetails?.agency,
    processType,
    calculateTimeDifferenceInSeconds,
    trackAction,
    navigate,
    onCloseComplete,
    t,
    showToast,
    setValue,
  ])

  const availableSteps = useMemo(() => {
    const steps = [CreateStep.DETAILS]

    // prevent select templates when we have predefined one
    if (!templatePresetId) {
      steps.push(CreateStep.TEMPLATE_FORM)
    }

    if (integrationAvailable) {
      steps.push(CreateStep.INTEGRATIONS)
    }

    return steps
  }, [templatePresetId, integrationAvailable])

  const [logError, setLogError] = useState(false)
  const handleNextStep = async () => {
    const isValid = await trigger()

    if (isValid) {
      setCurrentStepIdx(currentStepIdx + 1)
    } else {
      setLogError(true)
    }
  }

  const handleBack = () => {
    setCurrentStepIdx(currentStepIdx - 1)
  }

  useEffect(() => {
    if (logError) {
      console.error('Form is not valid', errors)
      setLogError(false)
    }
  }, [errors, logError])

  const handleCancelEdit = () => {
    onClose()

    const analyticsData = {
      workspace_name: tenant.name,
      workspace_type: tenant.tenantType,
      user_agency: userDetails?.agency,
      duration_in_seconds: calculateTimeDifferenceInSeconds(),
    }

    trackAction(ACTION_ANALYTICS.ACTION_PROJECT_CREATE_CANCELLED, analyticsData)
  }

  return (
    <>
      <FormProvider {...form}>
        <SideModal
          open={isOpen}
          formConfig={{ onSubmit }}
          onWppSideModalClose={handleCancelEdit}
          onWppSideModalCloseComplete={onCloseComplete}
          size="m"
          data-testid="create-project-modal"
        >
          {isTemplatesCountLoading || isIntegrationsLoading ? (
            <SideModalContentSkeleton />
          ) : (
            <>
              <WppTypography slot="header" type="2xl-heading">
                {t('modals.create_project.title')}
              </WppTypography>

              <>
                <div slot="body" className={styles.modalBody}>
                  {availableSteps.length > 1 && (
                    <>
                      <WppStepper orientation="horizontal" activeStep={currentStepIdx + 1} key={availableSteps.length}>
                        <WppStep className={styles.step}>
                          <p slot="label">{t('modals.create_project.tab.details')}</p>
                        </WppStep>
                        {availableSteps.includes(CreateStep.TEMPLATE_FORM) && (
                          <WppStep className={styles.step}>
                            <p slot="label">{t('modals.create_project.tab.templates')}</p>
                          </WppStep>
                        )}
                        {availableSteps.includes(CreateStep.INTEGRATIONS) && (
                          <WppStep className={styles.step}>
                            <p slot="label">{t('modals.create_project.tab.integrations')}</p>
                          </WppStep>
                        )}
                      </WppStepper>
                      <WppDivider className="wpp-spacing-24-bottom wpp-spacing-24-top" />
                    </>
                  )}
                  {availableSteps[currentStepIdx] === CreateStep.DETAILS && (
                    <>
                      <ProjectForm systemAttributes={systemAttributes} customAttributes={customAttributes} />
                      {templatePresetId && template && (
                        <Flex className={styles.presetContainer} direction="column" gap={12}>
                          <WppTypography slot="header" type="xl-heading">
                            {t('modals.create_project.selected_template')}
                          </WppTypography>
                          <TemplateCard className={styles.templatePreset} template={template} />
                        </Flex>
                      )}
                    </>
                  )}

                  {availableSteps[currentStepIdx] === CreateStep.TEMPLATE_FORM && (
                    <ProjectTemplateForm processType={processType} />
                  )}
                  {availableSteps[currentStepIdx] === CreateStep.INTEGRATIONS && (
                    <>
                      <Flex direction="column" className="wpp-spacing-24-bottom" gap={16}>
                        <WrikeConnect wrikeError={wrikeError} />
                      </Flex>

                      <WrikeIntegrationsMessage />
                    </>
                  )}
                </div>
                <Flex justify="between" slot="actions">
                  <Flex>
                    {availableSteps[currentStepIdx] !== CreateStep.DETAILS && (
                      <WppActionButton variant="primary" onClick={handleBack} data-testid="project-modal-back-button">
                        <WppIconArrow direction="left" className={styles.backIcon} />
                        {t('common.btn_back')}
                      </WppActionButton>
                    )}
                  </Flex>
                  <Flex gap={12}>
                    <WppButton variant="secondary" size="m" onClick={handleCancelEdit}>
                      {t('modals.create_project.btn_cancel')}
                    </WppButton>
                    {currentStepIdx + 1 < availableSteps.length ? (
                      <WppButton variant="primary" size="m" onClick={handleNextStep}>
                        {t('common.btn_next')}
                      </WppButton>
                    ) : (
                      <WppButton variant="primary" size="m" type="submit" loading={isSubmitting}>
                        {t('modals.create_project.btn_create')}
                      </WppButton>
                    )}
                  </Flex>
                </Flex>

                <Flex justify="between" slot="actions" />
              </>
            </>
          )}
        </SideModal>
      </FormProvider>
    </>
  )
}

export const { showModal: showCreateProjectModal } = createNiceModal<Props>(
  withAttributes<Props>(CreateProjectModal),
  'create-project-modal',
)
